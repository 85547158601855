@import "../../../../styles/2-tools/tools.color-square.scss";

.ContentCard {
	padding: var(--spacing--lg);
	background-color: var(--color-white);
	min-width: 0;
	@include color-square;
}

.ContentCard_link {
	margin-top: var(--spacing--md);
}
