.BusinessDeadlines {
	background-color: var(--color-white);
	padding: var(--spacing--lg);
	margin-top: var(--spacing--lg);
	&___noBackground {
		padding: 0;
	}
}
.BusinessDeadlines_heading {
	display: flex;
	column-gap: var(--spacing--lg);
}

.BusinessDeadlines_deadline {
	padding: var(--spacing--lg) 0;

	& + & {
		border-top: solid var(--border--sm) var(--color-bg-subtle);
	}
}
.BusinessDeadlines_deadlineWarning {
	color: var(--color-danger);
}

.BusinessDeadlines_buttons {
	display: flex;
	margin-top: var(--spacing--sm);
}

.BusinessDeadlines_buttonRight {
	margin-left: var(--spacing--3xl);
}

.BusinessDeadlines_date___exceededDeadline {
	text-decoration: line-through;
}
