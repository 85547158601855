@import "../../../styles/2-tools/tools.color-square.scss";
@import "../../../styles/2-tools/tools.media-query.scss";

.BackgroundContainer {
	display: inline-block;
	margin-top: var(--spacing--lg);
	width: 100%;
	min-width: 0;

	&___xsMargin {
		margin-top: 1px;
	}

	&___smMargin {
		margin-top: var(--spacing--sm);
	}

	&___noTopMargin {
		margin-top: 0;
	}
	&___colorSquare {
		@include color-square;
	}
	&___default {
		background-color: var(--color-white);
	}
	&___primaryAccent {
		background-color: var(--color-bg--light-blue);
	}
	&___grey {
		background-color: var(--color-bg--warm);
	}
	&___whiteBorder {
		background-color: var(--color-white);
		border: var(--border--sm) solid var(--color-primary);
	}
	&___primary20 {
		background-color: var(--color-primary--20);
	}
	&___primary40 {
		background-color: var(--color-primary--40);
	}

	&___centerChildren {
		@include mq("md") {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
		}
	}
}

.BackgroundContainer_wrapper {
	margin: var(--spacing--lg);
	&:last-child > :last-child {
		margin-bottom: 0;
	}

	&___noWrapperMargin {
		margin: 0;
	}
}
