@import "../../../styles/2-tools/tools.media-query.scss";

.ContentSection {
	margin-top: var(--spacing--lg);
	margin-bottom: var(--spacing--2xl);
	clear: both;

	@include mq("md") {
		:global(.u-aside) & {
			grid-column: 1;
		}
	}
	&___noTopMargin {
		margin-top: 0;
	}
	&:last-child,
	&:last-child > :last-child {
		margin-bottom: 0;
	}
	:global(.page-content) & {
		margin-bottom: var(--spacing--lg);
	}
}
