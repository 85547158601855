@import "../../../../styles/2-tools/tools.color-square.scss";
@import "../../../../styles/0-globals/globals.breakpoints";
@import "../../../../styles/2-tools/tools.media-query.scss";

.SearchCard {
	padding: var(--spacing--lg);
	background-color: var(--color-white);
	margin-top: 1px;

	@include mq("md", max) {
		width: 100%;
	}
}

.SearchCard_heading {
	margin-bottom: var(--spacing--md);
}

.SearchCard_teaser {
	margin-bottom: var(--spacing--sm);
}

.SearchCard_text {
	margin-top: var(--spacing--md);
}
