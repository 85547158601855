@import "../../../styles/2-tools/tools.color-square.scss";
@import "../../../styles/2-tools/tools.media-query.scss";

.NewsPage_container {
	margin-top: var(--spacing--lg);
}

.NewsPage_content {
	@include color-square;
	background-color: var(--color-white);
	padding: var(--spacing--lg);
	.NewsPage___additionalContent & {
		outline: 1px solid transparent;
	}
}

.NewsPage_Picture {
	height: 350px;
	margin-bottom: var(--spacing--lg);
	@include mq("md", max) {
		display: none;
	}
}

.NewsPage_expirationDate {
	color: var(--color-primary--80);
	margin-bottom: var(--spacing--sm);
	&___fullMargin {
		margin-bottom: var(--spacing--lg);
	}
}

.NewsPage_expiredAlert {
	background: var(--color-white);
	width: fit-content;
	padding: var(--spacing--sm);
	color: var(--color-primary);
	border: solid var(--border--sm) var(--color-primary);
	margin-bottom: var(--spacing--lg);
}
