@import "../../../styles/2-tools/tools.spacing-mixins.scss";
@import "../../../styles/2-tools/tools.media-query.scss";
.TopArea_topBanner {
	margin-bottom: var(--spacing--lg);
}
.TopArea_additional {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: var(--spacing--base);
	margin-bottom: var(--spacing--lg);
	border-bottom: 1px solid transparent;
	:global(.sop-app) & {
		padding-bottom: var(--spacing--lg);
	}
}
.TopArea_additionalWrapper {
	width: 100%;
	justify-content: space-between;
	&:not(.TopArea_additionalWrapper___noPadding) {
		@include side-padding;
	}
	background-color: var(--color-white);

	@include mq("md", max) {
		flex-direction: column;
		align-items: flex-start;
	}
	@include mq("sm", max) {
		padding: 0 var(--spacing--lg);
	}
	&___bottom {
		max-width: var(--width-md);
		margin-left: auto;
		margin-right: auto;
		:global(.sop-app) & {
			max-width: none;
			min-height: var(--spacing--2xl);
		}
	}
}

.TopArea_additionalContainer {
	max-width: var(--width-xl);
	margin-left: auto;
	margin-right: auto;
	gap: var(--spacing--sm);
	display: flex;
	align-items: center;
	.TopArea_additionalWrapper___top & {
		padding-top: var(--spacing--md);
		padding-bottom: var(--spacing--md);
	}
	.TopArea_additionalWrapper___bottom & {
		padding-top: var(--spacing--sm);
		padding-bottom: var(--spacing--sm);
	}
}

.TopArea_banner {
	&___noTopMargin {
		margin-top: 0;
	}
}
