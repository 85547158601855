.Result {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--sm);
	padding-bottom: var(--spacing--3xl);
}
.Result_row {
	text-align: right;
	&___partResult {
		text-decoration: underline;
	}
	&___final {
		font-weight: bold;
	}
	&___finalLeft {
		text-align: left;
		font-weight: bold;
	}
}
.Result_button {
	margin-top: var(--spacing--md);
}

.Result_subLabel{
	font-size: var(--font-size--xs);
	color: var(--color-primary--60);
}
