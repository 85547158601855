@import "../../../../styles/2-tools/tools.media-query.scss";

.ArticleHeader {
	margin-bottom: var(--spacing--md);
	max-width: var(--width-text-max);
}

.ArticleHeader_heading {
	width: 100%;
}

.ArticleHeader_lead {
	font-size: var(--font-size--xl);
	margin-top: var(--spacing--md);
	line-height: var(--teaser--line-height);
}
