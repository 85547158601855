.DocumentItem {
	display: flex;
	padding: var(--spacing--xs) 0;
	border-top: 1px solid var(--color-primary--20);
	&:last-of-type {
		margin-top: var(--spacing--lg);
	}
}

.DocumentItem_label {
	font-weight: var(--font-weight--semibold);
	margin: var(--spacing--sm) var(--spacing--xs) var(--spacing--sm) 0;
	max-width: 10rem;
	width: 100%;
}

.DocumentItem_content {
	margin-top: var(--spacing--sm);
}

.DocumentItem_links {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--md);
}
