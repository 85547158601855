@import "../../../styles/2-tools/tools.color-square.scss";
@import "../../../styles/2-tools/tools.media-query.scss";

.ArticlePage_content {
	background-color: var(--color-white);
	padding: var(--spacing--lg);
	@include color-square;
	overflow: hidden;
}

.ArticlePage_container {
	margin-top: 1px;
}
.ArticlePage_wrapper {
	&___medium {
		max-width: var(--width-md);
	}

	&___large {
		max-width: var(--width-lg);
	}

	&___xlarge {
		max-width: var(--width-xl);
	}
}
