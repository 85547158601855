@import "../../../styles/2-tools/tools.color-square.scss";
@import "../../../styles/2-tools/tools.media-query.scss";

.SimplePage_content {
	background-color: var(--color-white);
	padding: var(--spacing--lg);
	@include color-square;
	overflow: hidden;
}

.SimplePage_container {
	margin-top: var(--spacing--lg);
}
