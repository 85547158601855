@import "../../../styles/2-tools/tools.media-query";
@import "../../../styles/2-tools/tools.rem.scss";

.TeaserModule {
	margin-top: var(--spacing--lg);
	width: 100%;

	&___noTopMargin {
		margin-top: 0;
	}
}

.TeaserModule_top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: var(--spacing--md);
	background-color: var(--color-white);
	margin-bottom: pxToRem(2);
	@include mq("sm", max) {
		flex-direction: column;
		align-items: start;
	}
}

.TeaserModule_items {
	display: grid;
	grid-template-rows: 1fr;
	gap: pxToRem(1);

	&___1 {
		grid-template-columns: 1fr;
	}
	&___2 {
		grid-template-columns: 1fr 1fr;
	}
	&___3 {
		grid-template-columns: 1fr 1fr 1fr;
	}
	&___4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	@include mq("lg", max) {
		&___3,
		&___4 {
			grid-template-columns: 1fr 1fr;
		}
	}
	@include mq("sm", max) {
		&___2,
		&___3,
		&___4 {
			grid-template-columns: 1fr;
		}
	}
}
