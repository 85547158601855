@import "../../../styles/2-tools/tools.media-query.scss";

.Dropdown {
	display: flex;
	position: relative;
	align-items: center;
	gap: var(--spacing--md);
}

.Dropdown_label {
	display: block;
}

.Dropdown_wrapper {
	display: block;
	width: var(--width-input);
	&___width100 {
		width: 100%;
	}
}

.Dropdown_input {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--color-primary);
	color: var(--color-white);
	border: solid var(--border--sm) var(--color-primary);
	padding: 0.75rem var(--spacing--md);
	transition: background-color 0.3s;
	cursor: pointer;
	&:after {
		display: inline-block;
		margin-left: 0.2em;
		vertical-align: 0.2em;
		content: "";
		border-top: var(--border--2xl) solid;
		border-right: var(--border--lg) solid transparent;
		border-bottom: 0;
		border-left: var(--border--lg) solid transparent;
		transition: transform ease-in-out 0.2s;
	}
	&[aria-expanded="true"]:after {
		transform: rotate(0.5turn);
	}

	&:focus,
	&:hover {
		box-shadow: 0 0 0 0.3rem var(--color-primary--60);
		outline: solid var(--border--sm) var(--color-white);
		background-color: var(--color-primary--80);
	}

	.Dropdown___hasError & {
		border-color: var(--color-danger);
		&:focus {
			outline: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color--danger);
		}
	}
	.Dropdown___light & {
		background-color: var(--color-white);
		color: var(--color-black);
	}
}

.Dropdown_menu {
	background-color: var(--color-white);
	border: var(--border--sm) solid rgba(0, 0, 0, 0.15); // TODO
	border-radius: 0 0 4px 4px;
	min-width: fit-content;
	left: 0;
	position: absolute;
	top: 100%;
	width: 100%;
	z-index: 100;
}

.Dropdown_option {
	display: block;
	width: 100%;
	padding: var(--spacing--xs) var(--spacing--lg);
	clear: both;
	text-align: inherit;
	text-decoration: none;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	&[aria-selected="true"],
	&:hover,
	&:focus {
		background-color: var(--color-hightlight-blue);
	}
}
