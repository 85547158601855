@import "../../../../styles/2-tools/tools.color-square.scss";
@import "../../../../styles/2-tools/tools.typography-mixins.scss";
@import "../../../../styles/2-tools/tools.action.scss";

.LoginCard {
	padding: var(--spacing--lg);
	background-color: var(--color-white);
	min-width: 0;
	@include color-square;
}

.LoginCard_wrapper {
	text-align: center;
}

.LoginCard_button {
	margin: auto;
	margin-bottom: var(--spacing--sm);
	padding: var(--spacing--sm) calc(var(--spacing--2xl) * 2);
}

.LoginCard_error {
	@include error-message;
}
