@import "../../styles/2-tools/tools.media-query.scss";

.SalaryWithholding_grid {
	background-color: var(--color-white);
	max-width: var(--width--base);
	padding: var(--spacing--md);
}

.SalaryWithholding_row {
	padding-top: var(--spacing--md);
	padding-bottom: var(--spacing--md);
}
