@import "../../../../styles/2-tools/tools.color-square.scss";
@import "../../../../styles/2-tools/tools.media-query.scss";

.ContentCard {
	width: 100%;
}

.ResponseCard_box {
	width: 100%;
	display: block;
	margin: var(--spacing--md) 0;
	padding: var(--spacing--md);
	border: var(--border--md) solid var(--color-primary);
	background-color: var(--color-primary--40);

	@include mq("md") {
		padding: var(--spacing--sm) var(--spacing--lg);
	}
}

.ResponseCard_box:not(.ResponseCard_box___error) {
	.ResponseCard_message::before {
		content: "";
		position: absolute;
		display: inline-block;
		width: 0.5rem;
		height: 0.8rem;
		border-style: solid;
		border-width: 0 var(--border--md) var(--border--md) 0;
		transform: translate(-250%, -15%) rotate(45deg);
	}
}

.ResponseCard_box___success {
	border: var(--border--md) solid var(--color-success);
	background-color: var(--color-bg--success);
	.ResponseCard_message {
		margin-left: var(--spacing--lg);
	}
}

.ResponseCard_box___error {
	border: var(--border--md) solid var(--color-error);
	background-color: var(--color-bg--error);

	.ResponseCard_message::before {
		content: "!";
		display: inline-block;
		margin: 0 calc(var(--spacing--md) / 2);
		font-weight: var(--font-weight--bold);
	}
}

.ResponseCard_message {
	display: flex;
	align-items: center;
	max-width: none;
}
