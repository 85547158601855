@import "../../../../styles/2-tools/tools.media-query.scss";

.FormSpacingWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: var(--spacing--md);
	@include mq("md", max) {
		flex-direction: column;
	}

	&___justifyContentUnset {
		justify-content: unset;
	}
}

.FormSpacingWrapper_label {
	.FormSpacingWrapper___largeField & {
		flex: 1;
	}
	.FormSpacingWrapper___smallField & {
		flex: 2;
	}

	@include mq("md", max) {
		padding-right: 0;
	}

	&___labelCentered {
		margin-top: 0.75rem;
	}
}

.FormSpacingWrapper_field {
	.FormSpacingWrapper___largeField & {
		flex: 1;
	}
	.FormSpacingWrapper___smallField & {
		flex: 1;
	}
}
