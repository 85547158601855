.CalculationsComponent_smallText {
	font-size: 75%;
	font-weight: 400;
	color: var(--color-primary--80);
}

.CalculationsComponent_finalResult {
	padding-top: var(--spacing--sm);
	border-top: solid var(--border--sm) var(--color-primary);
}

.CalculationsComponent_display {
	margin-top: var(--spacing--sm);
	margin-bottom: var(--spacing--sm);
	&___inline {
		display: flex;
		flex-direction: row;
	}
	&___result {
		margin-left: auto;
	}
}
