.Result {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--sm);
	padding-bottom: var(--spacing--3xl);
}

.Result_minor {
	font-size: var(--spacing--md);
}
.Result_right {
	margin-right: 0;
	margin-left: auto;
}
.Result_verticalSpacing {
	padding: var(--spacing--sm) 0;
}
.Result_padding {
	margin: auto;
	padding: var(--spacing--lg) 0;
}
.Result_row {
	text-align: center;
	&___final {
		font-weight: bold;
	}
}
