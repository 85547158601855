@import "../../../styles/2-tools/tools.media-query.scss";

.Payment {
	margin-left: calc(-1 * var(--spacing--2xl));
	margin-right: calc(-1 * var(--spacing--2xl));
	border-top: var(--border--2xl) solid var(--color-bg-subtle);
	padding: var(--spacing--2xl) var(--spacing--2xl) 0 var(--spacing--2xl);
}

.Payment_heading {
	padding-top: var(--spacing--md);
	padding-left: var(--spacing--md);
}

.Payment_grid {
	padding: var(--spacing--md) var(--spacing--xl);

	@include mq("md", max) {
		padding: var(--spacing--md) 0;
	}
}

.Payment_result {
	padding-top: var(--spacing--sm);
	&___final {
		padding-top: var(--spacing--md);
		margin-top: var(--spacing--md);
		border-top: 1px solid var(--color-black);
	}
}

.Payment_cell {
	padding-top: var(--spacing--md);
	padding-bottom: var(--spacing--md);
}

.Payment_reset {
	display: flex;
	padding-top: var(--spacing--md);
	justify-content: flex-end;
}

.Payment_spacing {
	padding-bottom: var(--spacing--xl);
}
