@import "../../../styles/2-tools/tools.media-query.scss";

$inputUnitWidth: 80px;
$inputWidth: calc(var(--width-input) + $inputUnitWidth);

.TravelStep {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--xl);
}

.TravelStep_fieldLabel {
	flex: 1;
	padding-right: var(--spacing--md);
	@include mq("md", max) {
		padding-right: 0;
	}
}

.TravelStep_fieldValue {
	flex: 1;
}
