@import "../../../../styles/2-tools/tools.media-query.scss";

.ErrorCard {
	width: 100%;
	background-color: var(--color-white);
	padding: 0;
	@include mq("md") {
		padding: var(--spacing--lg) 0;
	}
}

.ErrorCard_wrapper {
	padding: var(--spacing--lg);
	border: var(--border--sm) solid var(--color-error);
	margin: auto;
	width: 100%;
	@include mq("md") {
		width: 70%;
	}
}

.ErrorCard_header {
	margin-bottom: var(--spacing--md);
}
