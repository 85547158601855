@import "../../../styles/2-tools/tools.media-query.scss";

.DebtForm {
	padding-top: var(--spacing--md);
	padding-bottom: var(--spacing--md);
}

.DebtForm_heading {
	padding: var(--spacing--md);
}

.DebtForm_grid {
	@include mq("md") {
		padding: var(--spacing--md) var(--spacing--xl);
	}
}

.DebtForm_cell {
	padding-top: var(--spacing--md);
	padding-bottom: var(--spacing--md);
}

.DebtForm_inputGrid {
	@include mq("md", max) {
		flex-direction: column;
	}
}

.DebtForm_field {
	@include mq("md", max) {
		width: 70%;
	}
	@include mq("sm", max) {
		width: 100%;
	}
}

.DebtForm_label {
	padding: 0 var(--spacing--md);
}

.DebtForm_submit {
	display: flex;
	justify-content: flex-end;
}
