@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.rem.scss";

.SearchPage_heading {
	margin-bottom: var(--spacing--md);
}
.SearchPage_container {
	background-color: var(--color-white);
	padding: var(--spacing--base) var(--spacing--lg);
	margin-bottom: var(--spacing--lg);
	margin-top: var(--spacing--lg);
	&___noTopMargin {
		margin-top: 0;
	}
}

.SearchPage_results {
	@include mq("lg") {
		width: calc(100% - pxToRem(300));
	}
}
