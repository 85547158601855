@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.display-mixins.scss";

.Shortcuts {
	width: 100%;
	--shortcuts-bg-color: var(--color-white);

	&___noTopMargin {
		margin-top: 0;
	}
}

.Shortcuts_wrapper {
	margin: var(--spacing--lg) auto;
	max-width: var(--width-md);
}

.Shortcuts_heading {
	background-color: var(--shortcuts-bg-color);
	padding: var(--spacing--lg);
	margin-bottom: 2px;
	&___hidden {
		@include visually-hidden;
	}
}

.Shortcuts_links {
	background-color: var(--shortcuts-bg-color);
	display: flex;
	padding: var(--spacing--lg);

	@include mq("md", max) {
		overflow-x: scroll;
	}
}
