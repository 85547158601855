@import "../../../../styles/2-tools/tools.rem.scss";

.ArticleMedia {
	width: 100%;
	&___margined {
		margin-bottom: var(--spacing--md);
	}
	&___spacing {
		margin-top: var(--spacing--lg);
		margin-bottom: var(--spacing--xl);
	}
	&___widthXSmall {
		max-width: pxToRem(150);
	}
	&___widthSmall {
		max-width: pxToRem(300);
	}
	&___widthMedium {
		max-width: pxToRem(400);
	}
	&___widthLarge {
		max-width: pxToRem(600);
	}
	&___widthTextWidth {
		max-width: var(--width-text-max);
	}
	&___widthMaxWidth {
		max-width: 100%;
	}
	// Horizontal position controlled from block when width controlled
	&___widthControlled {
		&.ArticleMedia___Left {
			margin-right: auto;
		}
		&.ArticleMedia___Right {
			margin-left: auto;
		}
		&.ArticleMedia___Center {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&___vTop {
		margin-bottom: auto;
	}
	&___vCenter {
		margin-top: auto;
		margin-bottom: auto;
	}
	&___vBottom {
		margin-top: auto;
	}
}

.ArticleMedia_figureCaption {
	font-size: var(--font-size--xs);
	margin-top: var(--spacing--sm);
}

.ArticleMedia_mediabox {
	width: 100%;
}

.ArticleMedia_picture {
	display: block;
}

// Horizontal position controlled from element when height controlled
.ArticleMedia_picture,
.ArticleMedia_figureCaption {
	.ArticleMedia___heightControlled & {
		display: flex;
	}
	.ArticleMedia___heightControlled.ArticleMedia___Left & {
		justify-content: left;
	}
	.ArticleMedia___heightControlled.ArticleMedia___Right & {
		justify-content: right;
	}
	.ArticleMedia___heightControlled.ArticleMedia___Center & {
		justify-content: center;
	}
}
//figcaption position when width controlled
.ArticleMedia_figureCaption {
	.ArticleMedia___widthControlled & {
		display: flex;
	}
	.ArticleMedia___widthControlled.ArticleMedia___Left & {
		justify-content: left;
	}
	.ArticleMedia___widthControlled.ArticleMedia___Right & {
		justify-content: right;
	}
	.ArticleMedia___widthControlled.ArticleMedia___Center & {
		justify-content: center;
	}
}
