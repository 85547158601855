@import "../../../styles/2-tools/tools.color-square.scss";
@import "../../../styles/2-tools/tools.media-query.scss";

.CatalogPage_wrapper {
	width: 100%;
	margin-top: var(--spacing--lg);
	margin-bottom: 1px;
	&___medium {
		max-width: var(--width-md);
	}

	&___large {
		max-width: var(--width-lg);
	}

	&___xlarge {
		max-width: var(--width-xl);
	}
}

.CatalogPage_contentHeader {
	display: flex;
	@include mq("md") {
		justify-content: space-between;
	}
	@include mq("md", max) {
		flex-direction: column;
	}
}

.CatalogPage_heading {
	margin: 0;
	margin-bottom: var(--spacing--lg);
	@include mq("md", max) {
		order: 1;
	}
}

.CatalogPage_cta {
	@include mq("md", max) {
		order: 0;
		display: flex;
		justify-content: flex-end;
	}
}

.CatalogPage_teaser {
	margin-bottom: var(--spacing--lg);
}

.CatalogPage_content {
	background-color: var(--color-white);
	padding: var(--spacing--lg);
	@include color-square;
	&___noTopMargin {
		margin-top: 0;
	}
}

.CatalogPage_pagination {
	margin-top: var(--spacing--sm);
	border-bottom: none;
	padding-bottom: 0;
}
