@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.rem.scss";
@import "../../../styles/2-tools/tools.color-square.scss";

.BannerCard {
	max-width: var(--width-md);
	margin: var(--spacing--lg) auto 0 auto;
	display: flex;
	width: 100%;
	height: 100%;

	&___reverse {
		flex-direction: row-reverse;
	}

	@include mq("sm", max) {
		flex-direction: column;
	}
}

.BannerCard_content {
	display: flex;
	flex-flow: column;
	width: 65%;
	flex: 1;
	padding: var(--spacing--lg);

	@include mq("md", max) {
		width: 50%;
	}
	@include mq("sm", max) {
		width: auto;
		height: 100%;
	}

	@include color-square(currentColor);
}

.BannerCard_manchet {
	font-size: var(--font-size--xl);
}

.BannerCard_media {
	position: relative;
	width: 35%;
	aspect-ratio: 5 / 4;

	@media print {
		display: none;
	}

	@include mq("md", max) {
		width: 50%;
	}
	@include mq("sm", max) {
		width: auto;
		min-height: pxToRem(252);
		aspect-ratio: auto;
	}

	&___contained {
		aspect-ratio: auto;
	}

	&___hideOnMobile {
		@include mq("sm", max) {
			display: none;
		}
	}

	&___video {
		aspect-ratio: auto;
	}
}

.BannerCard {
	:global(.Picture__override).BannerCard_picture___cover {
		position: absolute;
		left: 0;
		top: 0;
	}
}
