@import "../../../styles/2-tools/tools.color-square.scss";

.DocumentCatalogPage_container {
	background-color: var(--color-white);
	padding: var(--spacing--lg);
	@include color-square;
	margin-bottom: var(--spacing--lg);
	margin-top: var(--spacing--lg);
	&___noTopMargin {
		margin-top: 0;
	}
}
.DocumentCatalogPage_heading {
	margin-bottom: var(--spacing--lg);
}
