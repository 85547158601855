.Checkbox {
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 2.25rem;

	&___inline {
		display: inline-flex;
		margin-right: 1.5rem;
	}
}

.Checkbox_input {
	position: absolute;
	left: 0;
	z-index: -1;
	width: 1.5rem;
	height: 1.5rem;
	opacity: 0;
	box-sizing: border-box;
	padding: 0;

	&:focus + .Checkbox_label {
		&::before {
			box-shadow: 0 0 0 var(--border--md) var(--color-white), 0 0 0 var(--border--3xl) var(--color-primary--60);

			.Checkbox___hasError & {
				box-shadow: 0 0 0 var(--border--md) var(--color-white), 0 0 0 var(--border--3xl) var(--shadow-color--danger);
			}
		}
	}

	&:checked + .Checkbox_label {
		&::before {
			color: var(--color-white);
			border-color: var(--color-primary);
			background-color: var(--color-primary);
		}
		&::after {
			background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23fff%27 d=%27M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z%27/%3e%3c/svg%3e");
		}
	}
}

.Checkbox_label {
	cursor: pointer;
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
	display: inline-block;
	.Checkbox___hasError & {
		color: var(--color-error);
		&::before {
			border: var(--border--md) solid var(--color-error);
		}
	}

	&::before {
		border-radius: 0.25rem;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

		position: absolute;
		top: 0rem;
		left: -2.25rem;
		display: block;
		width: 1.5rem;
		height: 1.5rem;
		pointer-events: none;
		content: "";
		background-color: var(--color-white);
		border: var(--border--md) solid var(--color-primary);
		.Checkbox___disabled & {
			background-color: var(--color-primary--20);
			border: var(--border--md) dotted var(--color-primary);
		}
	}

	&::after {
		position: absolute;
		top: 0rem;
		left: -2.25rem;
		display: block;
		width: 1.5rem;
		height: 1.5rem;
		content: "";
		background: 50%/50% 50% no-repeat;
	}
}
