.ContentPage {
	display: block;
	:global(.sop-app) & {
		padding-bottom: var(--spacing--3xl);
	}
}

.ContentPage_topWrapper {
	margin-top: var(--spacing--lg);
}

.ContentPage_wrapper {
	width: 100%;
	margin-top: var(--spacing--lg);
	&___noTopMargin {
		margin-top: 0;
	}
	margin-bottom: 1px;
	margin-left: auto;
	margin-right: auto;
	&___medium {
		max-width: var(--width-md);
	}

	&___large {
		max-width: var(--width-lg);
	}

	&___xlarge {
		max-width: var(--width-xl);
	}
}
