@import "../../../styles/2-tools/tools.color-square.scss";

.DocumentPage_container {
	margin-top: var(--spacing--lg);
}
.DocumentPage_heading {
	margin-bottom: var(--spacing--base);
}

.DocumentPage_content {
	background-color: var(--color-white);
	@include color-square;
	padding: var(--spacing--lg);
}
