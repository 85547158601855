.DataSummaryCollapse {
	margin-bottom: var(--spacing--md);
}

.DataSummaryCollapse_content {
	padding-right: var(--spacing--lg);
}

.DataSummaryCollapse_content > p {
	color: var(--color-primary--80);
	margin-bottom: var(--spacing--sm);
	&:last-child {
		margin-bottom: 0;
	}
}
