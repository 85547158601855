.InputLabel {
	display: flex;
}

.InputLabel_wrapper {
	display: flex;
	flex-direction: column;
}

.InputLabel_label {
	margin-bottom: 0;
}

.InputLabel_labelSubtext {
	font-size: var(--font-size--sm);
	color: var(--color-primary--60);
}

.InputLabel_helpTextContent {
	max-height: 50vh;
	overflow-y: auto;
}
