@import "../../../../styles/2-tools/tools.media-query.scss";

.InputLabelWrapper {
	margin-top: var(--spacing--md);
	display: flex;
	flex-direction: row;
	align-items: center;
	@include mq("md", max) {
		flex-direction: column;
		align-items: left;
	}
}

.InputLabelWrapper_label {
	margin: 0 auto 0 0;
	@include mq("md", max) {
		padding: 0 0 var(--spacing--md) 0;
	}
}

.InputLabelWrapper_input {
	margin-left: var(--spacing--md);
	width: 25rem;
	@include mq("md", max) {
		width: 100%;
		margin: 0;
	}
}
