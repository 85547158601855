.SalaryAfterTax_padding {
	margin: auto;
	padding: var(--spacing--lg) 0;
}
.SalaryAfterTax_row {
	text-align: center;
	&___strong {
		font-weight: bold;
	}
}
