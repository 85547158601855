.BaseSelect {
	display: inline-block;
	width: 100%;
	height: 3.125rem;
	padding: 0.75rem 2rem 0.75rem 1rem;
	background: var(--color-white)
		url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e")
		right 1rem center/8px 10px no-repeat;
	border: 1px solid var(--color-primary);
	appearance: none;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	&:focus {
		outline: 0;
		box-shadow: 0 0 0 0.2rem var(--color-primary--60);
	}

	&___isInvalid {
		border-color: var(--color-danger);
		&:focus {
			outline: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color--danger);
		}
	}

	&___disabled {
		background-color: var(--color-primary--20);
		border: var(--border--sm) dotted var(--color-primary);
	}
}
