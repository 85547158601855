@import "../../../styles/2-tools/tools.media-query.scss";

.RentalStep {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--xl);
}

.RentalStep_totalIncome {
	display: flex;
	@include mq("md", max) {
		flex-direction: column;
	}
	font-weight: var(--font-weight--semibold);
}
.RentalStep_totalIncomeLabel {
	flex: 2;
}
.RentalStep_totalIncomeNumber {
	flex: 1;
	text-align: right;
	border-bottom: double 4px var(--color-primary);
}
