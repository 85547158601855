@import "../../../styles/2-tools/tools.rem.scss";

.SituationSelector {
	&___lightBlue {
		background-color: var(--color-bg--light-blue);
		margin-left: calc(var(--spacing--lg) * -1);
		margin-right: calc(var(--spacing--lg) * -1);
		padding: var(--spacing--lg);
	}
	&___grey {
		background-color: var(--color-bg--warm);
		margin-left: calc(var(--spacing--lg) * -1);
		margin-right: calc(var(--spacing--lg) * -1);
		padding: var(--spacing--lg);
	}
	&___whiteBorder {
		margin-left: -7px;
		margin-right: -7px;
		border: var(--border--sm) solid var(--color-primary);
		padding: var(--spacing--lg);
		padding-left: 7px;
	}
}

.SituationSelector_selectorGroup {
	.SituationSelector___centered & {
		max-width: var(--width-text-max-sm);
		margin-left: auto;
		margin-right: auto;
	}
}

.SituationSelector_heading {
	margin-bottom: var(--spacing--lg);
}

.SituationSelector_richText {
	margin-bottom: var(--spacing--lg);
}

.SituationSelector_groupLabel {
	margin-bottom: var(--spacing--sm);
}

.SituationSelector_choices {
	.SituationSelector___centered & {
		display: grid;
		justify-items: start;
		--auto-grid-min-size: calc(var(--width-text-max-sm) * 0.33);
		grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
		grid-template-rows: auto;
	}
}

.SituationSelector_choice {
	.SituationSelector___centered & {
		max-width: calc(var(--width-text-max-sm) * 0.33);
	}
}

.SituationSelector_resultCard {
	.SituationSelector___centered & {
		max-width: var(--width-text-max-sm);
		margin-left: auto;
		margin-right: auto;
	}
	&___loadingSpinner {
		text-align: center;
	}
}

.SituationSelector_resultCard___noBackground {
	margin-top: var(--spacing--2xl);
	margin-bottom: var(--spacing--2xl);
}

.SituationSelector_resultCard___numberLines {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		border-left: var(--border--sm) dotted var(--color-primary--60);
		height: calc(100% + var(--spacing--sm));
		left: 20px;
		top: 40px;
	}
}

.SituationSelector_numberWrapper {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	width: 100%;
}

.SituationSelector_numberPoint {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: pxToRem(40);
	height: pxToRem(40);
	margin-right: var(--spacing--md);
	color: var(--color-primary--60);
	background: var(--color-white);
	border: var(--border--md) solid currentColor;
}

.SituationSelector_numberContent {
	padding-top: 6px;
	flex: 1;
}
