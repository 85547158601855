@use "sass:math";
@import "../../styles/2-tools/tools.rem.scss";

@mixin color-square($color: var(--color-primary), $distanceToLeft: var(--spacing--lg)) {
	position: relative;

	&::before {
		content: "";
		display: block;
		width: pxToRem(35);
		height: pxToRem(10);
		background: $color;
		position: absolute;
		left: $distanceToLeft;
		top: 0px;
	}

	&[class*="col"] {
		padding-top: var(--spacing--lg);
	}
}
@mixin color-square-large($color: var(--color-primary), $distanceToLeft: var(--spacing--lg)) {
	position: relative;

	&::before {
		content: "";
		display: block;
		width: pxToRem(70);
		height: pxToRem(10);
		background: $color;
		position: absolute;
		left: $distanceToLeft;
		top: 0px;
	}

	&[class*="col"] {
		padding-top: var(--spacing--lg);
	}
}
