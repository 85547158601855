@import "../../../../styles/2-tools/tools.typography-mixins.scss";
@import "../../../../styles/2-tools/tools.media-query.scss";

.ShortcutCard {
	text-decoration: none;
	&:not(:last-child) {
		margin-right: var(--spacing--lg);
	}
	flex: 1 1;

	@include mq("md", max) {
		flex-direction: column;
		flex-shrink: 0;
		flex-basis: calc(28.571% - calc(var(--spacing--md) / 1.5));
		white-space: break-spaces;
	}
	@include mq("sm", max) {
		flex-basis: calc(40% - calc(var(--spacing--md) / 2.5));
	}
}

.ShortcutCard_textbox {
	@include heading--xs;
	word-break: break-word;
}

.ShortcutCard_link {
	display: block;
	color: inherit;
	text-decoration: none;
}

.ShortcutCard_media {
	display: block;
	margin-bottom: 0.5em;
	aspect-ratio: 16 / 9;

	@media print {
		display: none;
	}
}
