@import "../../../../styles/2-tools/tools.aspect-ratio.scss";
@import "../../../../styles/0-globals/globals.breakpoints";
@import "../../../../styles/2-tools/tools.media-query.scss";
@import "../../../../styles/2-tools/tools.spacing-mixins.scss";

.HeroCard {
	@media screen and (min-width: 576px) and (max-width: 1424px) {
		padding-left: var(--spacing--lg);
		padding-right: var(--spacing--lg);
	}

	&___noTopMargin {
		margin-top: 0;
	}
}
.HeroCard_wrapper {
	max-width: var(--width-md);
	margin: auto;
}
.HeroCard_media {
	display: block;
	margin-bottom: var(--spacing--xs);

	@media print {
		display: none;
	}

	&___fullWidth {
		margin:0 calc(50% - 50vw + 9px);
	}

	&___hideOnMobile {
		@include mq("md", max) {
			display: none;
		}
	}
}

.HeroCard___16x3 {
	:global(.PictureImage__override) {
		@include mq("sm", max) {
			max-height: 107px;
		}
		@include mq("sm") {
			max-height: 262px;
		}
	}
}

.HeroCard___16x6 {
	:global(.PictureImage__override) {
		@include mq("sm", max) {
			max-height: 215px;
		}
		@include mq("sm") {
			max-height: 525px;
		}
	}
}

.HeroCard___16x9 {
	:global(.PictureImage__override) {
		@include mq("sm", max) {
			max-height: 323px;
		}
		@include mq("sm") {
			max-height: 787px;
		}
	}
}

.HeroCard_content {
	@include mq("lg", min) {
		margin-top: calc(var(--spacing--xl) * -1);
		width: calc(var(--width-md) * 0.666); // TODO use 66.6% of page width, or ask UX to define
	}

	max-width: var(--width-md);
	margin-left: auto;
	margin-right: auto;
}
