@import "../../../../styles/2-tools/tools.media-query.scss";

.CalculationCard_header {
	margin-bottom: var(--spacing--sm);
	font-size: var(--font-size--sm);
}

.CalculationCard_calculation {
	display: flex;
	justify-content: space-between;
	margin: var(--spacing--xs) 0;

	@include mq("md") {
		justify-content: flex-start;
	}
	:first-child {
		min-width: none;
		@include mq("md") {
			min-width: calc(var(--width--base) / 2.5 - var(--spacing--md));
		}
	}
}

.CalculationCard_wrapper {
	font-size: var(--font-size--sm);
	> :first-child {
		text-decoration: underline;
	}
}

.CalculationCard_result {
	margin-top: var(--spacing--md);
	font-weight: var(--font-weight--bold);
	:last-child {
		text-decoration: underline;
	}
}
