@import "../../../../styles/2-tools/tools.media-query.scss";

.CustomsForm {
	padding: 0 var(--spacing--xl) var(--spacing--md) var(--spacing--xl);
}

.CustomsForm_cell {
	padding: var(--spacing--md) 0 var(--spacing--md) 0;
}

.CustomsForm_inputGrid {
	@include mq("md", max) {
		flex-direction: column;
	}
}

.CustomsForm_grid {
	@include mq("md") {
		padding: var(--spacing--md) 0 var(--spacing--md) 0;
	}
}
