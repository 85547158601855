@import "../../../../styles/2-tools/tools.media-query.scss";

.DateRangeInputField {
	display: flex;
	flex-direction: column;
	gap: var(--spacing--xl);
}

.DateRangeInputField_totalDays {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@include mq("md", max) {
		flex-direction: column;
	}
}

.DateRangeInputField_totalDaysElem {
	&___small {
		flex: 1;
	}
	&___big {
		flex: 2;
	}
}
