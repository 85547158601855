.IntermediateCalculationGroup_contentHeading {
	font-weight: var(--font-weight--semibold);
	margin-bottom: var(--spacing--md);
}

.IntermediateCalculationGroup_divider {
	border-bottom: var(--border--sm) solid var(--color-primary--20);
	margin-bottom: var(--spacing--md);
}

.IntermediateCalculationGroup_calculation {
	margin-bottom: var(--spacing--md);
}

.IntermediateCalculationGroup_calculationWrapper {
	display: flex;
	justify-content: space-between;

	&___isBold {
		font-weight: var(--font-weight--semibold);
	}
}

.IntermediateCalculationGroup_calculationLabelGroup {
	display: flex;
}

.IntermediateCalculationGroup_calculationSubLabel {
	font-size: var(--font-size--xs);
	color: var(--color-primary--60);
}
