// TODO rest of var are not defined in style utilities
.BaseInput {
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}

	width: 100%;
	position: relative;
	height: 3.125rem;
	padding: 0.75rem var(--spacing--md);
	color: var(--color-primary);
	background-color: var(--color-white);
	border: var(--border--sm) solid var(--color-primary);
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	&:focus {
		outline: 0;
		box-shadow: 0 0 0 0.2rem var(--color-primary--60);
	}

	&___textRight {
		text-align: right;
	}

	&___isInvalid {
		border-color: var(--color-danger);
		&:focus {
			outline: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color--danger);
		}
	}
	&___disabled {
		background-color: var(--color-primary--20);
		border: var(--border--sm) dotted var(--color-primary);
	}
}
