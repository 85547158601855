@import "../../../../styles/2-tools/tools.media-query.scss";

.SpecsCard {
	padding: var(--spacing--md);
	padding-bottom: 0;
}

.SpecsCard_icon {
	margin-right: var(--spacing--md);
}

.SpecsCard_headingWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	background-color: var(--color-white);
}

.SpecsCard_wrapper {
	width: 100%;
	margin-top: var(--spacing--sm);
	padding-left: 0;
	background-color: var(--color-white);
}

.SpecsCard_container {
	width: 100%;
	display: flex;
	gap: 0;
	flex-direction: column;
	@include mq("md") {
		flex-direction: row;
		gap: var(--spacing--md);
	}
}
