@import "../../../styles/2-tools/tools.color-square.scss";
@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.typography-mixins.scss";

.PublicationPage_container {
	margin-top: var(--spacing--lg);
}

.PublicationPage_content {
	background-color: var(--color-white);

	@include mq("md") {
		@include color-square(var(--color-primary), var(--spacing--xl));
		padding: var(--spacing--xl) var(--spacing--xl) var(--spacing--lg);
	}
	@include mq("md", max) {
		@include color-square;
		padding: var(--spacing--xl) var(--spacing--base) var(--spacing--base);
	}
}
