.ResultComponent {
	background-color: var(--color-white);
	padding: var(--spacing--xl);
}

.ResultComponent_content {
	padding: var(--spacing--xl) var(--spacing--xl) 0 var(--spacing--xl);
}

.ResultComponent_yearSelect {
	display: flex;
	flex-direction: row;
	margin-bottom: var(--spacing--2xl);
	&___text {
		margin-top: auto;
		margin-bottom: auto;
	}
	&___yearSelect {
		margin-left: var(--spacing--sm);
		width: 6rem; //TODO var?
	}
}
.ResultComponent_helpText {
	margin-left: var(--spacing--xs);
	margin-top: auto;
	margin-bottom: auto;
}
.ResultComponent_mainResult {
	display: flex;
	flex-direction: row;
	margin-bottom: var(--spacing--sm);
}

.ResultComponent_mainResultLabel {
	font-weight: var(--font-weight--bold);
}
.ResultComponent_mainResultResult {
	font-weight: var(--font-weight--bold);
	margin-left: auto;
	border-bottom: var(--border--xl) double;
}
.ResultComponent_mainResultDisclaimer {
	margin-bottom: var(--spacing--lg);
}
