@import "../../../styles/2-tools/tools.media-query.scss";

.TransportLinks {
	width: 100%;
	margin: var(--spacing--lg) auto 0;
	&___noTopMargin {
		margin-top: 0;
	}
}

.TransportLinks_assets {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1px;
	margin-top: 1px;
	@include mq("lg", max) {
		grid-template-columns: 1fr 1fr;
	}
	@include mq("sm", max) {
		grid-template-columns: 1fr;
	}
}
