@import "../../../styles/2-tools/tools.media-query.scss";
.Aside {
	margin-bottom: var(--spacing--lg);
	overflow: hidden;
	@include mq("lg") {
		grid-column: 2;
		grid-row: 1 / 100;
	}
	@include mq("lg", max) {
		margin-top: var(--spacing--lg);
	}

	@media print {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: var(--spacing--lg);
	}
}

.Aside > * {
	margin-bottom: var(--spacing--lg);
}
