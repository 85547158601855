@import "../../../../styles/2-tools/_tools.media-query.scss";

.NewsLetterTextBox {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: var(--spacing--lg);

	@include mq("sm", max) {
		grid-template-columns: 1fr;
		gap: var(--spacing--sm);
	}
}
